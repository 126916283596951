<template>
  <div class="product-wrap programme-pipe">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-pipe">
      <div class="txt"></div>
    </div>
    <div class="white-wrap">
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧管廊是以各类智能化监控设备为基础，数据融合分析应用为手段，结合智能传感、3S（GIS、GPS、RS）和三维建模等技术，</p>
        <p>实现对管廊各类信息的快速、准确、可靠的收集与处理，并在统一的信息管理平台上展现和操作。</p>
        <p>智慧感知：运用先进的传感和传输技术，全面感知管廊关键信息，对影响管廊正常运转的事故进行预警，实行超前控制。</p>
        <p>智慧管理：利用云计算、虚拟现实和物联网等先进技术，建立统一的智能监管平台，实现智慧化管理，提高运维效率。</p>
        <p>智慧决策：通过对海量数据进行分析挖掘，结合逻辑关系模型，萃取其中的有效信息，为运维决策提供有效辅助，提升管理水平。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box">
          <p>云昇智慧管廊管控平台的设计遵循了行业相关标准规范，符合信息共享与互联互通原则，实现了管廊监控运营的整体布局与协调配合，从实际需求出发，深入融合应用，为保证管廊监控运营指明了方向。</p>
          <p>
            平台包含PC端与手持移动客户端，运用物联网、云计算等信息技术，实现大数据与商业智能的运维管理的两大核心支撑体系，将分散的设备数据转换为系统的管理数据，变被动式运维为主动式运维，结合业务特征，在云端策略及经验库的指导下，实现对管廊的统一管理和优化控制，打造“城市智慧生命线”，并且运用平台战略在管廊全生命周期过程中持续为业主提供增值服务。</p>
        </div>
        <div class="auto-img mt-5">
          <img src="../../assets/img/programme/pipe/pic_1.png" alt="云昇方案">
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>平台功能</h4></div>
        <div class="card-box">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_2.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>平台功能</h6>
                  <div class="txt-info">
                    实现对管廊内环境参数、对排水、风机、照明、供配电附属设备运行状态、用电参数等进行实时监测。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_3.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>管廊设备智能控制</h6>
                  <div class="txt-info">
                    当管廊内发生异常时，系统可根据设置的阈值，对排水泵、排风机、报警器等实现智能控制。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_4.jpg" alt="平台功能">
                </div>
                <div class="txt">
                  <h6>管廊综合监控</h6>
                  <div class="txt-info">
                    各类监控数据及设备运行状态汇集到综合管廊监控中心，实现数据分析、集中展示及安全控制等。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_5.jpg" alt="管廊日常运维">
                </div>
                <div class="txt">
                  <h6>管廊日常运维</h6>
                  <div class="txt-info">
                    系统具备基础监控、运维管理、应急指挥等功能，集成GIS等业界最新技术，实现可视化高效化运维。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_6.jpg" alt="GIS一张图">
                </div>
                <div class="txt">
                  <h6>GIS一张图</h6>
                  <div class="txt-info">
                    各类监控数据及设备运行状态汇集到综合管廊监控中心，实现数据分析、集中展示及安全控制等。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="card-item">
                <div class="auto-img">
                  <img src="../../assets/img/product/pipe/pic_7.jpg" alt="报警信息推送">
                </div>
                <div class="txt">
                  <h6>报警信息推送</h6>
                  <div class="txt-info">
                    系统可根据报警信息，进行任务调度，根据报警类型，进行应急处置。可对报警数据进行查看分析。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>方案价值</h4></div>
        <div class="icon-info-box mt-5">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="icon-item">
                <div class="icon-box"><i class="iconfont icon-xianjinxing"></i></div>
                <div class="txt">
                  <h6>先进性与实用性</h6>
                  <div class="txt-info">
                    系统设计采用先进的设计理念、技术、方法，注意结构、设备的成熟性；系统采用专业化地理信息系统并结合GIS技术；系统功能设计全面实用，界面设计专业、友好。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="icon-item">
                <div class="icon-box"><i class="iconfont icon-anquan"></i></div>
                <div class="txt">
                  <h6>安全性与易用性</h6>
                  <div class="txt-info">
                    系统考虑信息资源的充分共享、保护和隔离，具有多层次的系统安全体；运行日志具有审计功能，能充分保证企业信息资源的安全性。系统具备自诊断功能，能识别硬件设备、数据库等。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="icon-item">
                <div class="icon-box"><i class="iconfont icon-jingji"></i></div>
                <div class="txt">
                  <h6>前瞻性与经济性</h6>
                  <div class="txt-info">
                    硬件和软件平台采用模块化设计，用户可在不影响已有系统结构的条件下，扩展新的系统单元和管廊支线。软件采用多层结构设计，极大减少了模块之间的耦合性，提高了系统的可扩充性。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="icon-item">
                <div class="icon-box"><i class="iconfont icon-linghuo"></i></div>
                <div class="txt">
                  <h6>通用性与特色性</h6>
                  <div class="txt-info">
                    系统具备通用的综合管廊的基础监控管理功能，涵盖了管廊的运维管理业务，融合了防灾减灾、应急指挥等功能。通过集成业界的最新技术如GIS技术等，实现了管理的可视化、高效化。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-pipe {
  .banner-pipe {
    background: url("../../assets/img/programme/pipe/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}
</style>
